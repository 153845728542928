define(['$window'], function($window) {

  const component = {};
  const _config = {
    videoPlayerContainer: '.videoHeroBanner_container',
    videoPlayer: 'videoHeroBanner_videoPlayer',
    videoPlayerButton: 'videoHeroBanner_videoPlayButton',
    videoImageAndPlayButtonContainer: 'videoHeroBanner_videoImageAndPlayButtonContainer',
    videoImageAndPlayButtonContainerHidden: 'videoHeroBanner_videoImageAndPlayButtonContainerHidden',
    imageAsset: 'img.videoHeroBanner_videoImageAsset',
    videoImage: '.videoHeroBanner_videoImage'
  };

  const videoHeroBanner = () => {
    const _init = (element) => {

      component.element = element;
      component.videoContainers = document.querySelectorAll(component.config.videoPlayerContainer);

      component.findAndBindWidgets();

      let cachedWidth = $window.screen.width;
      $window.addEventListener('resize', component.updateContainerHeight.bind(null, cachedWidth));
    };

    const _findAndBindWidgets = () => {
      component.videoContainers.forEach((container) => {
        let widgetId = container.getAttribute('data-widget-id');
        if (widgetId) {
          let videoPlayButton = container.querySelector(`#${component.config.videoPlayerButton}-${widgetId}`);
          let player = container.querySelector(`#${component.config.videoPlayer}-${widgetId}`);
          videoPlayButton.addEventListener('click', component.playVideo.bind(null, widgetId, player));
        }
      });

      component.updateContainerHeight();
    };

    const _playVideo = (widgetId, videoPlayer) => {
      let imageAndPlayButtonContainer = document.getElementById(`${component.config.videoImageAndPlayButtonContainer}-${widgetId}`);
      imageAndPlayButtonContainer.classList.add(component.config.videoImageAndPlayButtonContainerHidden);
      imageAndPlayButtonContainer.addEventListener('transitonend', function hideBannerAndFocusOnVideo() {
        imageAndPlayButtonContainer.hidden = true;
        videoPlayer.focus();
        imageAndPlayButtonContainer.removeEventListener('transitionend', hideBannerAndFocusOnVideo)
      });
      
      let parent = videoPlayer.parentNode;
      if (parent) {
        parent.style.height = '100%';
        videoPlayer.style.height = '100%';
      }
      videoPlayer.removeAttribute('aria-hidden');
      videoPlayer.removeAttribute('tabindex');
      videoPlayer.play();
    };

    const _updateContainerHeight = (cachedWidth) => {
      if ($window.screen.width !== cachedWidth) {
        let imageAsset = component.element.querySelector(component.config.imageAsset);
        if (imageAsset) {
          let widgetId = imageAsset.parentNode.parentNode.getAttribute('data-widget-id');
          let widgetHeight = (parseInt(window.getComputedStyle(imageAsset).height)) + 'px';
          component.setContainerHeight(widgetHeight, widgetId);
        }
      }
    };

    const _setContainerHeight = (height, widgetId) => {
      for (let container of component.videoContainers) {
        if (container.getAttribute('data-widget-id') === widgetId) {
          container.style.height = height;
        }
      }
    };

    component.config = _config;
    component.init = _init;
    component.findAndBindWidgets = _findAndBindWidgets;
    component.playVideo = _playVideo;
    component.updateContainerHeight = _updateContainerHeight;
    component.setContainerHeight = _setContainerHeight;

    return component;
  };

  return videoHeroBanner;
});
